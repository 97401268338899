/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.picsum-img-wrapper {
    height: 300px; /* Set to the height of the carousel */
    width: 100%; /* Set to the width of the carousel */
    overflow: hidden;
  }

  .picsum-img-wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .card-group-login-comp{
    margin: 20% 10%;
}

body > div:nth-child(1) > div > app-fakenewsidentifier > div > div.main-panel > div > button > span.mdc-button__label > h1 {
  color: black !important; 
}

.button-style {
  margin-top: 20%;
  color: black !important;
}

.button-style > button {
  color: black !important;
}


.validation-result {
  margin-top: 20%;
}

.fake-probability {
  font-size: 15pt;
}